// These are the styles to make some of the templates look extra nice.
// Feel free to remove these styles for production!


//
// Body Styles
//
body{
  text-align: center;
}
.body {
  //background: $black !important;
}
.body.nac-layout-2 {
  background: $nac-grey !important;
}
.container.header {
  background: $white;
}
.body-drip {
  border-top: 8px solid #663399;
}
//
// Full Width Headers
//
.wrapper.header {
  background: #6169AA;
}
.nac-layout-2 .wrapper.header {
  background: $nac-grey;
}
.header .columns {
  padding-bottom: 0;
}
.header p {
  color: #fff;
  padding-top: 15px;
}
.header .wrapper-inner {
  padding: 0;
}
.header .container {
  background: transparent;
}


//
// Header/hero graphic
//
.header-graphic img{
  //width: 80%;
}


//
// Main content
//
.wrapper.secondary {
  background: $nac-grey;
}
.body.nac-layout-1 .wrapper.secondary {
  background: $white;
}
.body.index-layout .wrapper.secondary .container {
  width: 55%;
  background: $nac-grey;
}
.content-footer{
  margin-bottom: 1em;
}
.content-dynamic a{
  color: $nac-purple;
}


//
// Footer
//
.wrapper.footer.nac-layout-1,
.wrapper.footer.nac-layout-1 .container,
.wrapper.footer.nac-layout-2 .container {
  background: $black;
}
.wrapper.footer p {
  font-size: $small-font-size;
}
.wrapper.footer.nac-layout-2,
.wrapper.footer.nac-layout-2.footer-contact-info .container {
  background: $nac-grey;
}
.wrapper.footer.nac-layout-2.footer-contact-info .container p{
	@media only screen and (max-width: #{$global-breakpoint}) {
      padding-left: 15% !important;
      padding-right: 15% !important;
	}
}
.wrapper.footer a:hover {
  text-decoration: underline;
}
.wrapper.footer .container p {
  color: $white;
  font-size: $small-font-size;
}
.wrapper.footer.nac-layout-2.footer-contact-info .container p {
  color: $black;
}
.wrapper.footer.nac-layout-2.footer-contact-info .container a {
  color: $nac-periwinkle;
}
.wrapper.footer.nac-layout-2.footer-contact-info .container a img{
  display: inline-block;
  margin-bottom: 1em;
}
.footer .social-icons h3{
	width: 100%;
	font-size: 1em;
	font-weight: 300;
	text-transform: uppercase;
	color: $white;
}
.footer .social-icon{
	width: 36px;
	margin-right: 4px;
	display: inline-block;
}
.footer .content-dynamic a{
  color: $nac-periwinkle;
}

//
// layout utilities
//
.fullwidth{
  //width: 100% !important;
}
.fullwidth.menu[align="center"]{
  width: 100% !important;
}
.fullwidth.menu th{
  text-align: left;
}
.margin-0{
  margin: 0 !important;
}
.pad-l{
	padding-left: $global-gutter;
}
.pad-l-0{
	padding-left: 0;
}
.pad-l-5{
	padding-left: 5px;
}
.pad-l-10{
	padding-left: 10px;
}
.pad-l-15{
	padding-left: 15px;
}
.pad-l-20{
	padding-left: 20px;
}
.pad-l-25{
	padding-left: 25px;
}
.pad-l-50{
	padding-left: 50px;
}
table.body .collapse .columns.small-pad-l-2col, table.body .collapse .column.small-pad-l-2col{
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
}
.columns.large-pad-l-50{
  padding-left: 50px;
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-left: $global-gutter;
  }
}
.columns.large-pad-l-20{
  @media only screen and (min-width: #{$global-breakpoint}){
    padding-left: 20px;
  }
}
.pad-r{
	padding-right: $global-gutter;
}
.pad-r-0,
table.body .columns.pad-r-0{
	padding-right: 0;
}
.pad-r-5{
	padding-right: 5px;
}
.pad-r-10{
	padding-right: 10px;
}
.pad-r-15{
	padding-right: 15px;
}
.pad-r-20{
	padding-right: 20px;
}
.pad-r-25{
	padding-right: 25px;
}
.pad-r-50{
	padding-right: 50px;
}
.columns.large-pad-r-50{
  padding-right: 50px;
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-right: $global-gutter;
  }
}
.columns.large-pad-r-20{
  @media only screen and (min-width: #{$global-breakpoint}){
    padding-right: 20px;
  }
}
.columns.pad-b-0{
  padding-bottom: 0 !important;
}
.columns.small-pad-b-0{
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-bottom: 0 !important;
  }
}
.columns.large-pad-b-0{
  @media only screen and (min-width: #{$global-breakpoint}){
    padding-bottom: 0 !important;
  }
}
.subs-benefits-icon{
	width: 20%;
	padding-right: 4%;
	padding-left: 2%;
  @media only screen and (min-width: #{$global-breakpoint}){
    width: 14%;
    padding-left: 2%;
  }
}
.subs-benefits-detail{
  width: 74%;
  @media only screen and (min-width: #{$global-breakpoint}){
    width: 80%;
  }
}
.kbyg-item-detail{
  padding-top: 0.5em;
}
.package-thumbnail{
	width: 100px !important;
	padding-right: 16px;
	padding-left: 26px;
}
.button-buy-package .button{
	max-width: 280px;
}


//
// border classes
//
.border-top,
.border-left,
.border-bottom,
.border-right{
  border-color: $black;
  border-style: solid;
}
.border-top{
  border-top-width: 1px;
}
.border-right{
  border-right-width: 1px;
}
.border-bottom{
  border-bottom-width: 1px;
}
.border-left{
  border-left-width: 1px;
}

//
// text utilities
//
p.lead{
  line-height: 1.3;
  font-size: 1.2em;
}
.content-footer p{
  line-height: 1;
  font-size: 0.9em;
}
.event-grid p{
  margin-bottom: 0.2em;
  hyphens: none;
}
.event-grid .date{
  text-transform: uppercase;
}
.nobr{
	white-space: nowrap;
}
.allcaps{
	text-transform: uppercase;
}
td,th {
	word-break: keep-all;
	-moz-hyphens: none;
	-ms-hyphens: none;
	-webkit-hyphens: none;
	hyphens: none;
}


//
// font sizing, margins & weights
//
h1{
  font-size: 32px;
}
.heading-subs-benefits{
	font-size: 18px;
	font-weight: bold;
}
h2 {
	margin-bottom: 8px;
}
.heading-subs-title{
	font-size: 20px;
	font-weight: 500;
}
h2,
h3,
h4{
  font-weight: 300;
}
.text-large{
  font-size: large !important;
}
.text-medium{
  font-size: medium !important;
}
.text-small{
  font-size: small !important;
}
.text-smaller{
  font-size: smaller !important;
}
p a:hover,
h2 a:hover,
h3 a:hover{
  text-decoration: underline;
}


//
// font colours
//
.content-dark h1,
.content-dark h2,
.content-dark h3,
.content-dark h4,
.content-dark li,
.content-dark p,
.content-dark a{
  color: $off-white;
}
.nac-yellow,
.text-yellow{ color: $nac-yellow; }
.text-red{ color: #9e0c27 !important; }
.text-white{ color: $white; }
.text-black{ color: $black; }
.text-dark-grey{ color: $primary-color; }
a.text-black{ text-decoration: underline; }
.nac-ncf-peach,
h2 .nac-ncf-peach,
h3 .nac-ncf-peach{ color: $nac-ncf-peach; }

a:visited{ color: inherit; }
a.nac-yellow:hover,
a.text-yellow:hover{ color: $nac-yellow; }
a.text-red:hover{ color: #9e0c27 !important; }
a.text-white:hover{ color: $white; }
a.text-black:hover{ color: $black; text-decoration:none; }

.nac-purple,
.text-purple{ color: $nac-purple; }
.pipe-large-grey{
  color: $white;
  @media only screen and (max-width: #{$global-breakpoint}){
    color: inherit;
  }
}

//
// background colours
//
table.container.bg-red{ background-color: #9e0c27; }
.callout{
  padding: 1em 2em 0.5em;
}
.callout.radius{
  border-radius: $global-radius;
}
.callout.notice{
  background-color: #FEF3CC;
  border: 1px solid #FDD757;
}

//
// images
//
.event-thumbnail{
  @media only screen and (max-width: #{$global-breakpoint}){
    max-width: 100% !important;
    height: auto !important;
  }
}
.header-image-wrapper th{
  width: 100%;
}
//
// icons
//
.item-icon{
  max-width: 30px;
  vertical-align: middle;
}
.main-graphic-icon{
  max-width: 40%;
  margin: 0 auto;
}
.main-graphic-icon path{
  fill: #6169AA;
}


//
// menus
//
table.menu.vertical th.menu-item table.menu.vertical td.menu-item,
table.menu.vertical th.menu-item table.menu.vertical th.menu-item{
  // padding-left: 36px;
}
.menu-item.text-left{
  text-align: left;
}
.menu-item a:hover,
.index-layout .callout h2 a:hover{
  text-decoration: underline;
}
.index-layout .callout h2{
  font-size: 1.1em;
}
.index-layout .callout h2 a,
.download-links a[download]{
  color: $black;
}

//
// buttons
//
.button-survey{
  min-width: 200px;
}
table.text-center.button td,
table.text-center.button td a{
  text-align: center;
}
.layout-abandoned-cart .event-button-tickets table td,
.layout-abandoned-cart .event-button-tickets:hover table td{
  background-color: $button-background;
}
.layout-abandoned-cart .event-button-tickets td a{
  color: $primary-color;
  font-weight: normal;
  background-color: $button-background;
  border-color: $button-background !important;
}
.layout-abandoned-cart .event-button-tickets td a:hover,
.layout-abandoned-cart .event-button-tickets.large:hover table tr td a{
  color: $primary-color;
  background-color: $button-background;
  text-decoration: underline;
}

.button table td a,
.button table td a:hover{
  background-color: $button-background;
}

//
// survey
//
.survey-buttons.columns.first,
.survey-buttons.columns.last{
  @media only screen and (min-width: 500px) and (max-width: 599px){
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  @media only screen and (min-width: 600px) and (max-width: #{$global-breakpoint}){
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
}
.survey-buttons .survey-button{
  margin: 0 auto;
}
.survey-buttons.survey-buttons-right .button{
  //float: none;
  display: inline-block;
  @media only screen and (min-width: #{$global-breakpoint}){
    //float: left;
    display: table;
    
  }
}
.survey-buttons.survey-buttons-left .button{
  //float: none;
  display: inline-block;
  @media only screen and (min-width: #{$global-breakpoint}){
    //float: right;
    display: table;
  }
}
.survey-buttons.survey-buttons-left{
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-left: 0 !important;
  }
  @media only screen and (min-width: #{$global-breakpoint}){
    padding-right: 0 !important;
  }
}
.mso .survey-buttons-left .survey-buttons-wrapper .last{
  //mso-padding-alt: 0;
}
.survey-buttons-left .survey-buttons-wrapper{
  //margin-left: 13px;
}
.survey-buttons.survey-buttons-right{
  @media only screen and (max-width: #{$global-breakpoint}){
    padding-right: 0 !important;
  }
  @media only screen and (min-width: #{$global-breakpoint}){
    padding-left: 0 !important;
  }
}
.mso .survey-buttons-right .survey-buttons-wrapper .last{
  //mso-padding-alt: 0;
}
.survey-buttons-right .survey-buttons-wrapper{
  //margin-right: 13px;
}
.survey-buttons.small-text-center th{
  @media only screen and (max-width: #{$global-breakpoint}){
    text-align: center !important;
  }
}
.survey-buttons .survey-button-wrapper{
  @media only screen and (max-width: #{$global-breakpoint}){
  width: 20% !important;
  }
}
a.download-button{
  background: $nac-yellow;
  padding: 8px 16px 8px 16px;
  margin: 0 4px;
  color: $black;
  border: 0 solid $nac-yellow;
  border-radius: $global-radius;
  display: inline-block;
  font-size: 1.3em;
  line-height: 2em;
  font-weight: $button-font-weight;

  @media only screen and (max-width: #{$global-breakpoint}){
	  font-size: 2em;
  }
}
.survey-buttons .survey-button a{
  font-size: 1.1em !important;
  line-height: 2em;
  font-weight: $button-font-weight;
  text-align: center;
  min-width: 20px;
}
a.survey-button:hover{
  text-decoration: underline;
}
a.download-button{
  border-width: 1px;
  border-color: $black;
}


//
// creation fund
//
.creationfund-newsletter .event-grid h2{
  color: $nac-ncf-peach;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.1em;
  margin-bottom: 0.2em;
}
.creationfund-newsletter .event-grid p{
  font-size: 0.9em;
}
.creationfund-newsletter .project-detail>table{
  @media only screen and (max-width: #{$global-breakpoint}){
	  width: 100% !important;
  }
}
.creationfund-newsletter .project-detail>table>tbody>tr>th{
  @media only screen and (max-width: #{$global-breakpoint}){
	  padding-left: 0 !important;
	  padding-right: 0 !important;
  }  
}


//
// some final overrides
//
.button.expanded {
  td:not(.expander) {
    width: 100%;
  }
}

table.button {

  table {

    td {
      mso-cellspacing: map-get($button-padding, default);
      mso-padding-alt: map-get($button-padding, default);
    }
  }
}

table.button.tiny {
  table td {
      mso-cellspacing: map-get($button-padding, tiny);
      mso-padding-alt: map-get($button-padding, tiny);
  }
}

table.button.small {
  table td {
      mso-cellspacing: map-get($button-padding, small);
      mso-padding-alt: map-get($button-padding, small);
  }
}

table.button.large {
  table td {
      mso-cellspacing: map-get($button-padding, large);
      mso-padding-alt: map-get($button-padding, large);
  }
}

//
// horizontal rule sizing
//
@media only screen and (min-width: #{$global-breakpoint}) {
  @for $i from 1 through $grid-column-count {
    hr.large-#{$i} {
      width: -zf-grid-calc-px($i, $grid-column-count, $global-width);
    }
  }
}

//
// fix for broken offset
//
@media only screen and (min-width: #{$global-breakpoint}) {
  @for $i from 1 through ($grid-column-count - 1) {
    td.large-offset-#{$i},
    td.large-offset-#{$i}.first,
    td.large-offset-#{$i}.last,
    th.large-offset-#{$i},
    th.large-offset-#{$i}.first,
    th.large-offset-#{$i}.last {
      //1.5 takes in effect a whole empty cell.
      padding-left: -zf-grid-calc-px($i, $grid-column-count, $global-width) + $global-gutter * 2 !important;
    }
  }
}