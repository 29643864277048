//  Foundation for Emails Settings
//  ------------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Grid
//   3. Block Grid
//   4. Typography
//   5. Button
//   6. Callout
//   7. Menu
//   8. Thumbnail


// NAC branding
// ---------

$nac-periwinkle: #838dc8;
$nac-purple: #6068b2;
$nac-yellow: #fdd757;
$nac-grey: #f6f6f6;
$nac-ncf-peach: #e35056;

// 1. Global
// ---------

$primary-color: #444444;
$secondary-color: #777777;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #ec5840;
$light-gray: #f3f3f3;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #000000;
$white: #fefefe;
$off-white: #ddd;
$pre-color: #ff6908;
$global-breakpoint: 720px;
$global-width-small: 100%;
$global-gutter: 26px;
$body-background: $nac-grey;
$container-background: $white;
$global-padding: 16px;
$global-margin: 16px;
$global-radius: 4px;
$global-rounded: 4px;
$global-width: $global-breakpoint - $global-gutter;


// 2. Grid
// -------

$grid-column-count: 12;
$column-padding-bottom: $global-padding;
$container-radius: 0;

// 3. Block Grid
// -------------

$block-grid-max: 8;
$block-grid-gutter: $global-gutter;

// 4. Typography
// -------------

$global-font-color: $primary-color;
$body-font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
$global-font-weight: lighter;
$global-header-weight: normal;
$header-color: inherit;
$global-line-height: 1.3;
$global-font-size: 17px;
$body-line-height: $global-line-height;
$header-font-family: $body-font-family;
$header-font-weight: $global-header-weight;
$h1-font-size: 34px;
$h2-font-size: 30px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;
$header-margin-bottom: 4px;
$paragraph-margin-bottom: 10px;
$small-font-size: 82%;
$small-font-color: $medium-gray;
$lead-font-size: $global-font-size * 1.8;
$lead-line-height: 1.6;
$text-padding: 10px;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-font-weight;
$subheader-margin-top: 4px;
$subheader-margin-bottom: 8px;
$hr-width: $global-width;
$hr-border: 1px solid $dark-gray;
$hr-margin: 20px auto;
$anchor-text-decoration: none;
$anchor-color: $nac-periwinkle;
$anchor-color-visited: $anchor-color;
$anchor-color-hover: inherit;
$anchor-color-active: $anchor-color-hover;
$stat-font-size: 40px;

// 5. Button
// ---------

$button-padding: (
  tiny: 4px 8px 4px 8px,
  small: 5px 10px 5px 10px,
  default: 8px 16px 8px 16px,
  large: 10px 20px 10px 20px,
);
$button-font-size: (
  tiny: 10px,
  small: 12px,
  default: 16px,
  large: 20px,
);
$button-color: $black;
$button-color-alt: $medium-gray;
$button-font-weight: 400;
$button-margin: 0 0 $global-margin 0;
$button-background: #fdd757;
$button-border: $global-rounded solid transparent;
$button-radius: $global-radius;
$button-rounded: $global-rounded;

// 6. Callout
// ----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-padding: 10px;
$callout-margin-bottom: $global-margin;
$callout-border: 1px solid darken($callout-background, 20%);
$callout-border-secondary: 1px solid darken($secondary-color, 20%);
$callout-border-success: 1px solid darken($success-color, 20%);
$callout-border-warning: 1px solid darken($warning-color, 20%);
$callout-border-alert: 1px solid darken($alert-color, 20%);

// 7. Menu
// -------

$menu-item-padding: 10px;
$menu-item-gutter: 10px;
$menu-item-color: $primary-color;

// 8. Thumbnail
// ------------

$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

